<template>
    <div class="">
        <myhead :param="param" />
        <section>
            <div  >
                 <img style="width: 100%;height: 600px;" :src="res.tb_image">
            </div>
        </section>
        <section>
            <div class="body1200">
                <div style="text-align: left;font-size: 18px;"  v-html="res.tb_content">

                </div>
            </div>
        </section>
        <section style="margin-top: 90px;">
            <div class="body1200"  >
                <div style="font-size: 42px;text-align: left;">相关案例</div>
                <div class="htable" style="justify-content: space-between;margin-top: 60px;">
                    <div class="hcell" style="width: 374px;height: 270px;background-color: #f3f3f3;overflow: hidden;border-radius: 15px;">
                        <div v-if="rows[0]">
                            <img :src="rows[0].tb_image">
                        </div>
                    </div>
                    <div class="hcell" style="width: 374px;height: 270px;background-color: #f3f3f3;overflow: hidden;border-radius: 15px;">
                        <div v-if="rows[1]">
                            <img :src="rows[1].tb_image">
                        </div>
                    </div>
                    <div class="hcell" style="width: 374px;height: 270px;background-color: #f3f3f3;overflow: hidden;border-radius: 15px;">
                        <div v-if="rows[2]">
                            <img :src="rows[2].tb_image">
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section style="height: 90px;"></section>
    </div>
</template>
<script>
    import myhead  from  '../components/myhead.vue'
    import { topicBase,listTopicBase } from "../hts/api.js"
    export default {
        name: "",

        components: {
            myhead,

        },
        data() {
            return {
                param: {
                    pageindex: 1,
                },
                res:{},
                rows:[],
            }
        },
        mounted() {
            this.getData()
        },
        methods: {
            getData () {
                console.log(this.id)
                topicBase({ id: this.$route.query.id })
                    .then(resData => {
                        this.res=resData.data.rows[0]
                        this.getList(this.res.tb_keywords);
                    })
                    .catch()
            },
            getList (id) {
                // console.log(this.id)
                listTopicBase({fbId: id, page: 1, rows: 3 })
                    .then(resData => {
                        this.rows=resData.data.rows
                    })
                    .catch()
            },
        },
    }
</script>
<style lang="css" scoped>

</style>
